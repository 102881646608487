import type { AuthSession, AuthUser } from 'aws-amplify/auth'
import {
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
} from 'aws-amplify/auth'
import { create } from 'zustand'
import { combine } from 'zustand/middleware'

import { getPartnerDataFromAuthSession } from '@/containers/SelectLocation'

/**
 * Store
 */

export const useAuthStore = create(
  combine(
    {
      currentUser: null as AuthUser | null,
      isAuthenticated: false,
      isLoading: true,
      partnerData: { name: undefined } as Record<string, any>,
      userAttributes: null as Record<string, string> | null,
    },
    (set) => ({
      initialize: async () => {
        try {
          const authSession = await fetchAuthSession()
          const authUser = await getCurrentUser()
          const userAttributes = await fetchUserAttributes()

          set((state) => ({
            ...state,
            currentUser: authUser,
            isAuthenticated: Boolean(authUser),
            isLoading: false,
            partnerData: getPartnerDataFromAuthSession(authSession),
            userAttributes,
          }))

          return authSession
        } catch {
          set((state) => ({ ...state, isLoading: false }))
        }
      },
      setPartnerData: (authSession: AuthSession) =>
        set((state) => ({
          ...state,
          partnerData: getPartnerDataFromAuthSession(authSession),
        })),
      setUser: (user: AuthUser) =>
        set((state) => ({
          ...state,
          currentUser: user,
          isAuthenticated: Boolean(user),
          isLoading: false,
        })),
      setUserAttributes: (userAttributes: Record<string, string>) =>
        set((state) => ({ ...state, userAttributes })),
    }),
  ),
)

/**
 * Helpers
 */

export function useCurrentUser() {
  return useAuthStore((state) => state.currentUser)
}

export function useUserAttributes() {
  return useAuthStore((state) => state.userAttributes)
}

export function usePartnerData() {
  return useAuthStore((state) => state.partnerData)
}

export function getPartnerData() {
  return useAuthStore.getState().partnerData
}
