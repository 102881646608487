import type { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import type {
  ApiError,
  FIRST_IDENTIFICATION_PARAMETER_INDEX,
  SECOND_IDENTIFICATION_PARAMETER_INDEX,
  THIRD_IDENTIFICATION_PARAMETER_INDEX,
} from '../types'

import type {
  CreateLeadRequest,
  Partner,
  PartnerLocation,
  ReportParams,
} from './types'
import * as partner from '.'

/*
  K E Y S
*/

export const partnerKeys = {
  all: ['partners'] as const,
  applicationDetail: (applicationId: string) =>
    [partnerKeys.detail(applicationId)] as const,
  compensation: (partnerId: string) => [partnerKeys.detail(partnerId)] as const,
  detail: (id: string) => [...partnerKeys.details(), id] as const,
  detailLocations: (id: string) =>
    [...partnerKeys.detail(id), 'locations'] as const,
  detailMetadata: (id: string) =>
    [...partnerKeys.detail(id), 'metadata'] as const,
  details: () => [...partnerKeys.all, 'detail'] as const,
  employee: () => ['employee'] as const,
  list: (params: Record<string, string>) =>
    [...partnerKeys.lists(), { params }] as const,
  lists: () => [...partnerKeys.all, 'list'] as const,
  locations: (params: any) =>
    [...partnerKeys.all, 'locations', params] as const,
  partner: (id: string) => [...partnerKeys.detail(id)] as const,
  receipt: (customerId: string, receiptId: string) =>
    [...partnerKeys.detail(customerId), 'receipt', receiptId] as const,
  reports: (params: ReportParams) =>
    [...partnerKeys.all, 'reports', params] as const,
  signaturesAnchors: (employeeId: string) =>
    [partnerKeys.employee(), employeeId, 'signaturesAnchors'] as const,
}

/*
  Q U E R I E S
*/

export function useGetSignatureAnchors(
  employeeId: string,
  options?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof partner.getSignatureAnchors>>,
      AxiosError
    >
  >,
) {
  return useQuery({
    queryFn: () => partner.getSignatureAnchors(employeeId),
    queryKey: partnerKeys.signaturesAnchors(employeeId),
    ...options,
  })
}

export function useEmployeeSelf(
  options?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof partner.getEmployeeSelf>>,
      AxiosError
    >
  >,
) {
  return useQuery({
    queryFn: partner.getEmployeeSelf,
    queryKey: partnerKeys.employee(),
    ...options,
  })
}

export function usePartnerLocations(
  partnerId: string,
  options?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof partner.getPartnerLocations>>,
      AxiosError
    >
  >,
) {
  return useQuery({
    placeholderData: [],
    queryFn: () => partner.getPartnerLocations(partnerId),
    queryKey: partnerKeys.detailLocations(partnerId),
    ...options,
  })
}

export function useLocations(
  params: Parameters<
    typeof partner.getLocations
  >[FIRST_IDENTIFICATION_PARAMETER_INDEX],
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof partner.getLocations>>,
    AxiosError
  >,
) {
  return useQuery({
    queryFn: () => partner.getLocations(params),
    queryKey: partnerKeys.locations(params),
    ...options,
  })
}

export function usePartnerData(
  partnerId: string,
  options?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof partner.getPartnerData>>,
      AxiosError
    >
  >,
) {
  return useQuery({
    queryFn: () => partner.getPartnerData(partnerId),
    queryKey: partnerKeys.partner(partnerId),
    ...options,
  })
}

export function usePartnerMeta(
  partnerId: string,
  options?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof partner.getMetadata>>, AxiosError>
  >,
) {
  return useQuery({
    queryFn: () => partner.getMetadata({ name: partnerId }),
    queryKey: partnerKeys.detailMetadata(partnerId),
    ...options,
  })
}

export function usePartners(
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof partner.getPartners>>,
    AxiosError
  >,
) {
  return useQuery({
    placeholderData: [],
    queryFn: () => partner.getPartners(),
    queryKey: partnerKeys.lists(),
    ...options,
  })
}

export function useReports(
  params: ReportParams,
  options?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof partner.getReports>>, AxiosError>
  >,
) {
  return useQuery({
    queryFn: () => partner.getReports(params),
    queryKey: partnerKeys.reports(params),
    ...options,
  })
}

export function _useDebugLink_(
  applicationId: string,
  options?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof partner.getDebugLink>>,
      AxiosError
    >
  >,
) {
  return useQuery({
    queryFn: () => partner.getDebugLink(applicationId),
    queryKey: partnerKeys.detail(applicationId),
    ...options,
  })
}

export function usePartnerApplication(
  applicationId: string,
  options?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof partner.getPartnerApplication>>,
      AxiosError
    >
  >,
) {
  return useQuery({
    queryFn: () => partner.getPartnerApplication(applicationId),
    queryKey: partnerKeys.applicationDetail(applicationId),
    ...options,
  })
}

export function usePartnerCompensation(
  applicationId: string,
  options?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof partner.getPartnerCompensation>>,
      AxiosError
    >
  >,
) {
  return useQuery({
    queryFn: () => partner.getPartnerCompensation(applicationId),
    queryKey: partnerKeys.compensation(applicationId),
    ...options,
  })
}

/*
  M U T A T I O N S
*/

export function useCreatePartnerLocation(
  partnerId: string,
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof partner.createLocation>>,
    AxiosError,
    Parameters<
      typeof partner.createLocation
    >[SECOND_IDENTIFICATION_PARAMETER_INDEX]
  >,
) {
  return useMutation({
    mutationFn: (body) => partner.createLocation(partnerId, body),
    ...options,
  })
}

export function useUpdateStatusPartnerLocation(
  partnerId: string,
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof partner.updateLocation>>,
    AxiosError,
    {
      locationId: string
      params: PartnerLocation
    }
  >,
) {
  return useMutation({
    mutationFn: ({ locationId, params }) =>
      partner.updateLocation(partnerId, locationId, params),
    ...options,
  })
}

export function useUpdatePartnerLocation(
  partnerId: string,
  locationId: string,
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof partner.updateLocation>>,
    AxiosError,
    Parameters<
      typeof partner.updateLocation
    >[THIRD_IDENTIFICATION_PARAMETER_INDEX]
  >,
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) =>
      partner.updateLocation(partnerId, locationId, body).then((response) => {
        const queryKey = partnerKeys.detailLocations(partnerId)

        const data = response?.data
        const oldQueryData =
          queryClient.getQueryData<PartnerLocation[]>(queryKey)
        const oldLocationIndex = oldQueryData?.findIndex(
          (location) => location.id === locationId,
        )

        if (oldLocationIndex >= 0) {
          oldQueryData[oldLocationIndex] = data
        }

        queryClient.setQueryData(queryKey, [...oldQueryData])

        return data
      }),
    ...options,
  })
}

export function useCreatePartner(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof partner.create>>,
    unknown,
    Parameters<typeof partner.create>[FIRST_IDENTIFICATION_PARAMETER_INDEX]
  >,
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) =>
      partner.create(body).then((response) => {
        const queryKey = ['partners']
        const data = response?.data
        const oldQueryData = queryClient.getQueryData<Partner[]>(queryKey) ?? []
        queryClient.setQueryData(queryKey, [data, ...oldQueryData])
        return data
      }),
    ...options,
  })
}

export function useCreateLead(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof partner.createLead>>,
    AxiosError<ApiError>,
    {
      body: CreateLeadRequest
    }
  >,
) {
  return useMutation({
    mutationFn: ({ body }) => partner.createLead(body),
    ...options,
  })
}

export function useUpdatePartner(
  partnerId: string,
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof partner.update>>,
    AxiosError,
    Parameters<typeof partner.update>[SECOND_IDENTIFICATION_PARAMETER_INDEX]
  >,
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body) =>
      partner.update(partnerId, body).then((response) => {
        const queryKey = ['partners']

        const data = response?.data

        const oldQueryData = queryClient.getQueryData<Partner[]>(queryKey) ?? []

        const oldPartnerIndex = oldQueryData?.findIndex(
          (partner) => partner.id === partnerId,
        )

        if (oldPartnerIndex >= 0) {
          oldQueryData[oldPartnerIndex] = data
        }

        queryClient.setQueryData(queryKey, [...oldQueryData])
        return data
      }),
    ...options,
  })
}

export function useUpdateEmployee(
  employeeId: string,
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof partner.updateEmployee>>,
    AxiosError,
    partner.UpdateEmployeeData
  >,
) {
  return useMutation({
    mutationFn: (body) => partner.updateEmployee(employeeId, body),
    ...options,
  })
}

export function useGenerateEmployeeContract(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof partner.generateEmployeeContract>>,
    AxiosError,
    { employeeId: string }
  >,
) {
  return useMutation({
    mutationFn: ({ employeeId }) =>
      partner.generateEmployeeContract(employeeId),
    ...options,
  })
}

export function useSignEmployeeContract(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof partner.signEmployeeContract>>,
    AxiosError,
    {
      body: {
        type: 'PUT'
        path: 'contract.signature'
        value: string
      }[]
      employeeId: string
    }
  >,
) {
  return useMutation({
    mutationFn: ({ body, employeeId }) =>
      partner.signEmployeeContract(employeeId, body),
    ...options,
  })
}

export function useUpdatePartnerApplication(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof partner.updatePartnerApplication>>,
    AxiosError,
    {
      applicationId: string
      body: Parameters<
        typeof partner.updatePartnerApplication
      >[FIRST_IDENTIFICATION_PARAMETER_INDEX]
    }
  >,
) {
  return useMutation({
    mutationFn: ({ body, applicationId }) =>
      partner.updatePartnerApplication(body, applicationId),
    ...options,
  })
}
