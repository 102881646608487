import { Text } from '@ocho/aurora'
import type { ComponentProps, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from '@/components/system'

import { Error } from '@/GlobalStyles'

import { Container, Label, Select as StyledSelect } from './styled'

interface Props {
  id?: string
  onBlur?: any
  onFocus?: any
  name?: string
  title?: string
  label?: string
  value?: string
  disabled?: boolean
  required?: boolean
  readOnly?: boolean
  placeholder?: string
  $primary?: boolean
  options?: Option[]
  $outlined?: boolean
  transparent?: boolean
  reducedLabel?: boolean
  error?: string | false
  hasDefaultOption?: boolean
  disableDefaultOption?: boolean
  onChange: (event: any) => any
  containerStyle?: ComponentProps<typeof Container>['style']
}

function Select(props: Props) {
  const {
    name,
    value = '',
    onChange,
    label,
    error,
    onBlur,
    onFocus,
    id = name,
    title = name,
    options = [],
    disabled = false,
    readOnly = false,
    placeholder,
    $primary = false,
    $outlined = false,
    transparent = false,
    hasDefaultOption = true,
    disableDefaultOption = true,
    containerStyle,
    ...otherProps
  } = props

  const { t } = useTranslation()

  const handleMouseDown = (event: MouseEvent<HTMLSelectElement>) => {
    if (readOnly) {
      event.preventDefault()
    }
  }

  return (
    <Container style={containerStyle}>
      <Flex
        as={Label}
        flexDirection="column"
        gap="var(--space--small)"
        htmlFor={id}
      >
        {label ? (
          <Text $size="small" $weight="medium">
            {label}
          </Text>
        ) : null}
        <StyledSelect
          $outlined={$outlined}
          $primary={$primary}
          $transparent={transparent}
          aria-invalid={Boolean(error)}
          aria-label={name}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onMouseDown={handleMouseDown}
          readOnly={readOnly}
          title={title}
          value={value}
          {...otherProps}
        >
          {hasDefaultOption || placeholder ? (
            <option disabled={disableDefaultOption} value="">
              {placeholder ??
                (options.length > 0 ? t('select') : t('noOptionsAvailable'))}
            </option>
          ) : null}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </StyledSelect>
      </Flex>
      {error ? <Error aria-hidden={!error}>{t(`${error}`)}</Error> : null}
    </Container>
  )
}

export type Option = {
  label: string
  value: string
}

export default Select
