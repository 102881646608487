import { Helmet } from 'react-helmet-async'

import { isPartner } from '@/utils/constants'
import { StorageKey } from '@/utils/constants/enums'
import { isDevelop } from '@/utils/constants/env'
import * as storage from '@/utils/storage'

export function PageMeta({ title }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta content={title} property="og:title" />
    </Helmet>
  )
}

const themeConfig = {
  color: isPartner ? '#C2417D' : '#11CD67',
  icon: `/icons/favicon-${isPartner ? 'partner' : 'servicing'}${isDevelop ? '-dev' : ''}.png`,
  touchIcon: `/touch-icon-${isPartner ? 'partner' : 'servicing'}.png`,
} as const

export default function Headers() {
  const locale =
    storage.get(StorageKey.Language) ?? navigator.language.replace(/-.*/, '')
  const defaultTitle = `OCHO ${isPartner ? 'Partner' : 'Servicing'}`

  return (
    <Helmet defaultTitle={defaultTitle} titleTemplate={`%s - ${defaultTitle}`}>
      <html lang={locale} />
      <meta content={themeConfig.color} name="theme-color" />
      <link href={themeConfig.icon} rel="icon" />
      <link href={themeConfig.touchIcon} rel="apple-touch-icon" />
    </Helmet>
  )
}
